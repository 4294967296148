<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 12 : 2"
      class="mx-auto"
    >
      <v-card-text class="my-4 text-center text-h6">{{title}}</v-card-text>
      <v-card-text class="text-center">
        <vue-upload-component
          :extensions="extensions"
          :accept="contentType"
          :custom-action="onFilePick"
          :multiple="false"
          :drop="true"
          :drop-directory="false"
          @input-filter="inputFilter"
          @input-file="inputFile"
          v-model="files"
          :input-id="name"
          :ref="name">
          <v-icon x-large color="green lighten-1">cloud_upload</v-icon>
        </vue-upload-component>
      </v-card-text>
      <v-card-text class="text-center">
        <v-avatar
          class="ma-3"
          size="100"
          tile
        >
          <v-img v-if="files[0] && files[0].thumb" :src="files[0].thumb"></v-img>
        </v-avatar>      
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<style>
.drop-active {
  position: relative;
  top: 0;
  right: 0;
  width: 200px;
  height: 100px;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  color: #fff;
  padding: 0;
}
</style>

<script>
import VueUploadComponent from 'vue-upload-component'

export default {
  name: 'FileUpload',
  data: () => ({
    files: [],
  }),
  components: {
    VueUploadComponent,
  },
  mounted: function () {
    console.log('this.$props.name:' + this.$props.name)
  },  
  props: {
    contentType: {
      type: String,
      required: true
    },
    extensions: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    name: {
      type: String
    }
  },
  methods: {
    async onFilePick(file, component) {
      
      // console.log('component!', file)

      this.$emit('filePicked', file)
      
      return new Promise((resolve, reject) => {
        resolve()
      })
      
    },
    inputFilter(newFile, oldFile, prevent) {
      console.log('newFile.type', newFile.type)
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
          console.log('newFile.blob', newFile.blob)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)
        console.log('this.$props', this.$props)
        this.$refs[this.$props.name].active = true
      }
    },
    clear() {
      this.$refs[this.$props.name].clear()
    }
  },
}
</script>